.flex-container {
	display: flex;
	background-color: #fafafa;
	justify-content: space-between;
}

.container-main {
	background-color: #ffffff;
	border-radius: 8px;
	margin-top: 20px;
	padding: 0px 15px 15px 15px;
}

.date-picker-item {
	display: flex;
	width: 100%;
	height: 80px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.date-picker-item-left {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.depart-date-text {
		font-size: 13px;
		color: #999999;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.date-text-left {
		font-size: 17px;
		color: #333333;
		font-weight: bold;
	}

	.date-text-right {
		font-size: 13px;
		margin-left: 5px;
		color: #333333;
	}
}

.right-icon {
	width: 6px;
	height: 10px;
}

.person-item {
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 17px;

	.person-item-text {
		font-weight: bold;
		columns: #333333;
		font-size: 16px;
	}

	.person-item-right {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.person-item-img {
		height: 20px;
		width: 20px;
		padding: 3px;
	}

	.person-item-right {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 44px;
	}

	.person-item-num {
		font-size: 17px;
		color: #333333;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 44px;
		width: 50px;
	}
}
