.order-list-container {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 50px;


  position: relative;
  box-sizing: border-box;

  .app-header {
    border-bottom: 1px solid #e1e1e1;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a4a4a;
    flex-direction: row;
    a {
      color: #4a4a4a;
      position: absolute;
      left: 30px;
      text-decoration: none;
    }
  }
  .ss-next-btn-container {
    height: 50px;
    display: flex;
    background-color: #547eff;
    color: white;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 17px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  .title-view {
    background: #ffffff;
    padding: 15px;
    display: flex;
    justify-content: left;
    .name-font {
      font-size: 14px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 14px;
    }
    .phone-number-font {
      margin-left: 20px;
      font-size: 15px;
      font-family: DIN-Medium;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 15px;
    }
    .depart-date-font {
      font-size: 13px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(153, 153, 153, 1);
      line-height: 13px;
    }
    .arrive-date-font {
      margin-left: 20px;
      font-size: 13px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(153, 153, 153, 1);
      line-height: 13px;
    }
  }
  .list-container {
    margin-top: 10px;
    background: #ffffff;

    .list-item-container {
      padding: 15px;
      display: flex;

      .list-item-title-container {
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        justify-content: space-around;
        width: 100vh;

        .eliminat-item-synopsis-container{
          display: flex;
          flex-direction: row;
          justify-content: left;

          .list-item-synopsis-font {
            font-size: 12px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(92, 92, 92, 1);
            line-height: 12px;
          }
          .eliminat-item-synopsis-font{
            margin-left: 20px;
            font-size: 12px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(92, 92, 92, 1);
            line-height: 12px;
          }
        }
        .list-item-synopsis-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .list-item-title-font {
            font-size: 13px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 13px;
          }
          .list-item-status-font {
            font-size: 12px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
            line-height: 12px;
          }

          .list-item-synopsis-font {
            font-size: 12px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(92, 92, 92, 1);
            line-height: 12px;
          }
          .list-item-examine {
            font-size: 12px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(84, 126, 255, 1);
            line-height: 12px;
          }
        }
      }
      .item-icon {
        margin-left: 15px;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 4px;
      }
    }
  }

  .verify-view {
    background: #ffffff;
    margin-top: 10px;
    display: flex;
    padding: 15px;
    justify-content: space-between;
    .verify-view-title {
      font-size: 13px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(92, 92, 92, 1);
      line-height: 13px;
    }
    .verify-view-status {
      font-size: 13px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      color: rgba(84, 126, 255, 1);
      line-height: 13px;
    }
  }
  .QRCode-view {
    background: #ffffff;
    justify-content: center;
    display: flex;
    padding-bottom: 15px;
  }
  .order-info-view {
    background: #ffffff;
    margin-top: 10px;
    padding: 15px;
    display: flex;
    .order-info-title-view {
      display: flex;
      flex-direction: column;
      .order-info-title-font {
        height: 20px;
        font-size: 13px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(92, 92, 92, 1);
        line-height: 20px;
      }
    }
  }
}
