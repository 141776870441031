.order-list-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
  box-sizing: border-box;

  .eliminat-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;

    .title-view {
      background: #ffffff;
      padding: 15px;
      display: flex;
      justify-content: left;
      .name-font {
        font-size: 14px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 14px;
      }
      .phone-number-font {
        margin-left: 20px;
        font-size: 15px;
        font-family: DIN-Medium;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 15px;
      }
    }
    .list-container {
      margin-top: 10px;
      background: #ffffff;

      .list-item-container {
        padding: 15px;
        display: flex;

        .list-item-title-container {
          display: flex;
          margin-left: 10px;
          flex-direction: column;
          justify-content: space-around;
          width: 100vh;

          .eliminat-item-synopsis-container {
            display: flex;
            flex-direction: row;
            justify-content: left;

            .list-item-synopsis-font {
              font-size: 12px;
              font-family: PingFang-SC-Medium;
              font-weight: 500;
              color: rgba(92, 92, 92, 1);
              line-height: 12px;
            }
            .eliminat-item-synopsis-font {
              margin-left: 20px;
              margin-right: 20px;
              font-size: 12px;
              font-family: PingFang-SC-Medium;
              font-weight: 500;
              color: rgba(92, 92, 92, 1);
              line-height: 12px;
            }
          }
          .list-item-synopsis-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .list-item-title-font {
              font-size: 13px;
              font-family: PingFang-SC-Bold;
              font-weight: bold;
              color: rgba(51, 51, 51, 1);
              line-height: 13px;
            }
            .list-item-status-font {
              font-size: 12px;
              font-family: PingFang-SC-Medium;
              font-weight: 500;
              color: rgba(153, 153, 153, 1);
              line-height: 12px;
            }
          }
        }
        .item-icon {
          margin-left: 15px;
          width: 50px;
          height: 50px;
          min-width: 50px;
          min-height: 50px;
          border-radius: 4px;
        }
      }
    }

    .order-info-view {
      background: #ffffff;
      margin-top: 10px;
      padding: 15px;
      display: flex;
      .order-info-title-view {
        display: flex;
        flex-direction: column;
        .order-info-title-font {
          height: 20px;
          font-size: 13px;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          color: rgba(92, 92, 92, 1);
          line-height: 20px;
        }
      }
    }
  }

  .ss-next-btn-container {
    height: 50px;
    display: flex;
    background-color: #547eff;
    color: white;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 17px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}
