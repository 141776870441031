* {
  padding: 0;
  margin: 0;
}
.map {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
#mapDiv .amap-indoormap-floorbar-control {
  bottom: 50%;
  right: 0;
}
.amap-logo {
  display: none!important;
}
.amap-copyright {
  display: none!important;
}
.amap-info-content {
  border-radius: 15px;
}
.amap-info-close {
  right: 10px!important;
}
// .amap-icon img{
//   width: 30px;
//   height: 30px;
//   animation: mymove 2s linear 1s infinite;
// }
.map-content {
  width: 60px;
  text-align: center;
  img {
    width: 30px;
    height: 30px;
    animation: mymove 2s linear 1s infinite;
  }
  span{
    display: block;
    position: relative;
    top: -2px;
    font-size: 11px;
    font-weight: bold;
  }
}

@keyframes mymove
{
  0% {
    transform: scale(0.5,0.5);
  }
  50% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(0.5,0.5);
  }
}
