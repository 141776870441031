* {
  padding: 0;
  margin: 0;
}
.station-screen {
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-overflow-scrolling: touch;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    height: 44px;
    background-color: #fff;
    color: #000000;
    font-size: 18px;
    .am-navbar-left {
      padding-left: 0;
    }
    .am-navbar-right {
      margin-right: 0;
    }
    .am-navbar-title {
      color: #000000;
      font-weight: 500;
    }
  }
  .content {
    width: 100%;
    height: 98vh;
    position: fixed;
    top: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    .am-tabs-default-bar-underline {
      display: none;
    }
    .am-tabs-default-bar-content {
      background-color: #ffffff;
    }
    .am-tabs-default-bar-tab::after {
      height: 0!important;
    }
    .am-tabs-default-bar-tab-active {
      font-weight: bold;
      color: #2A73FE;
    }
    .am-tabs-default-bar-tab-active::after {
      width: 50px!important;
      height: 5px!important;
      border-radius: 3px!important;
      background: #2A73FE!important;
      margin: auto!important;
      left: 0!important;
      right: 0!important;
    }
    .info {
      background-color: #fff;
      margin: 15px 15px 0 15px;
      .names {
        display: flex;
        span {
          flex: 1;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          color: #999999;
        }
      }
      .listNames {
        display: flex;
        span {
          flex: 1;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
    }
  }
}
