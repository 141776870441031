body {
	padding: 0;
	margin: 0;
}

.select-service-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;

	.ss-data-container {
		display: flex;
		flex-direction: row;

		.ss-date-container {
			width: 23vw;
			min-width: 23vw;
			min-height: 90%;
			overflow: scroll;
			margin-bottom: 50px;

			.ss-item-left-container {
				height: 54px;
				min-height: 54px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				background-color: #f7f7f7;

				.ss-item-left-blue-line {
					background-color: #547eff;
					height: 20px;
					width: 4px;
				}

				.ss-item-left-date {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}

				.ss-item-left-num {
					width: 13px;
					height: 13px;
					border-radius: 100%;
					background-color: red;
					color: white;
					font-size: 8px;
					margin-left: -12px;
					margin-top: -30px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.ss-right-container {
			width: 77vw;

			.ss-right-top-container {
				height: 56px;
				display: flex;
				flex-direction: row;
				align-items: center;
				overflow-x: scroll;
				padding-left: 12px;
				padding-right: 12px;

				.ss-right-top-btn {
					/* min-width: 50px; */
					/* overflow: hidden; */
					white-space: nowrap;
					/* text-overflow: ellipsis; */
					text-align: center;
					border-radius: 16px;
					color: #547eff;
					font-size: 13px;
					background-color: #edf1ff;
					margin-right: 12px;
					padding: 4px;
				}
			}

			.ss-right-bottom-container {
				display: flex;
				flex-direction: column;
				overflow: scroll;
				height: 80vh;
				padding-bottom: 60px;

				.ss-right-bottom-type-container {
					padding-left: 15px;
					padding-right: 15px;
					margin-top: 10px;

					.type-commodities-name {
						font-size: 14px;
						color: #333333;
						font-weight: bold;
						margin-top: 10px;
					}

					.type-item-container {
						display: flex;
						flex-direction: column;
						min-height: 110px;
						margin-top: 10px;
						background-color: #ffffff;

						.type-item-close {
							min-width: 30px;
							display: flex;
							flex-direction: row-reverse;
							margin: 5px;
						}

						.type-item-right-container {
							display: flex;
							flex-direction: row;
							align-items: center;

							.type-item-image {
								height: 50px;
								width: 50px;
								min-width: 50px;
								min-height: 50px;
								margin-left: 20px;
								margin-right: 10px;

								img {
									height: 100%;
									width: 100%;
								}
							}

							.type-item-message {
								display: flex;
								flex-direction: column;
								justify-content: center;

								.type-item-message-title {
									color: #333333;
									font-size: 13px;
									font-weight: bold;
									padding: 1px;

									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}

								.type-item-message-detail {
									display: inline-block;
									font-size: 12px;
									color: #5c5c5c;
									margin-top: 10px;
								}

								.type-item-message-number-container {
									margin-top: 10px;
									font-size: 15px;
									color: #5c5c5c;
									display: flex;
									flex-direction: row;
									justify-content: space-between;

									.type-item-message-change-num {
										height: 14px;
										display: flex;
										align-items: center;

										img {
											height: 100%;
										}

										.type-item-message-num {
											font-size: 15px;
											color: #333333;
											margin-left: 6px;
											margin-right: 6px;
										}
									}
								}
							}
						}
					}
				}
			}

			.ss-right-bottom-no-data {
				min-height: 70vh;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #666666;
				font-size: 15px;
			}
		}
	}

	.ss-next-btn-container {
		height: 50px;
		position: absolute;
		bottom: 0;
		display: flex;
		flex-direction: row;

		.ss-total-price-container {
			width: 50vw;
			background-color: white;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: #333333;
			text-align: center;
		}

		.ss-next-btn {
			width: 50vw;
			background-color: #547eff;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: white;
		}
	}
}
