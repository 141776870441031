.order-list-container {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 40px;

  .app-header {
    border-bottom: 1px solid #e1e1e1;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4a4a4a;
    flex-direction: row;
    a {
      color: #4a4a4a;
      position: absolute;
      left: 30px;
      text-decoration: none;
    }
  }

  .order-details-container {
    display: flex;
    flex-direction: column;

    .order-details-img {
      width: 375px;
      height: 395px;
    }

    .order-details-content{
        height: 40vh;
        margin-top: 10px;
        background: rgba(255, 255, 255, 1);
    }

    .order-details-title-container {
      background: rgba(255, 255, 255, 1);
      padding: 15px;

      .order-details-title {
        font-size: 15px;
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        line-height: 15px;
      }

      .order-details-info{
        display: flex;
        justify-content: space-between;  
        margin-top: 10px;

        .order-details-price {
            font-size: 12px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(224, 49, 49, 1);
            line-height: 15px;
            .order-details-price-number{
            font-size: 15px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: rgba(224, 49, 49, 1);
            line-height: 15px;
            }
          }
    
          .order-details-phone {
            font-size:15px;
            font-family:SFProText-Regular;
            font-weight:400;
            color:rgba(92,92,92,1);
            line-height:13px;
          }

      }
      
    }
  }
}
