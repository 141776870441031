.station-list {
  height: 100%;
  width: 100%;
  background-color: #F9F9F9;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .header {
    height: 45px;
    border-bottom: 1px solid #F9F9F9;
  }
  .content {
    background-color: #F9F9F9;
    .first, .second {
      display: flex;
      flex-direction: column;
      padding: 18px 14px;
      margin: 14px;
      border-radius:4px;
      .text-time {
        margin-bottom: 5vh;
        span {
          font-size: 13px;
          color: #999999;
          &:nth-child(2) {
            margin-left: 16px;
          }
          &:nth-child(3) {
            float: right;
          }
        }
      }
      .list-wrapper {
        height: 70vh;
        overflow-y: scroll;
        .list-item {
          .item-text {
            display: flex;
            position: relative;
            .imgIcon {
              position: absolute;
              left: 10px;
              top: -3px;
              img {
                height: 24px;
                width: 24px;
              }
            }
            .markIcon {
              position: absolute;
              right: 0px;
              top: -3px;
              img {
                height: 24px;
                width: 24px;
              }
            }
            .point {
              width: 72px;
              span {
                float: right;
                position: relative;
                top: 2px;
                right: -6px;
                display: block;
                height: 12px;
                width: 12px;
                background-color: #C5C5C5;
                border-radius: 50%;
                &.active {
                  background-color: #2A73FE;
                }
              }
            }
            .desc {
              flex: 1;
              margin-left: 30px;
              font-size: 15px;
              color: #333;
              &.active {
                color: #2A73FE;
              }
              span {
                &:nth-child(2) {
                  margin-left: 5px;
                }
              }
            }
            @media (max-width: 320px) {
              .desc{
                width:140px;
                white-space:nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .item-line{
            height: 43px;
            width: 72px;
            border-right: 1px solid #C5C5C5;
          }
          &:last-child{
            .item-line {
              height: 0;
              width: 0;
              border-right: none;
            }
          }
        }
      }
    }
    .am-tabs-default-bar-underline {
      display: none;
    }
    .am-tabs-default-bar-content {
      background-color: #ffffff;
    }
    .am-tabs-default-bar-tab::after {
      height: 0!important;
    }
    .am-tabs-default-bar-tab-active {
      font-weight: bold;
      color: #2A73FE;
    }
    .am-tabs-default-bar-tab-active::after {
      width: 50px!important;
      height: 5px!important;
      border-radius: 3px!important;
      background: #2A73FE!important;
      margin: auto!important;
      left: 0!important;
      right: 0!important;
    }
  }
}
