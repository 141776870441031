body {
  padding: 0;
  margin: 0;
}

.confirmation-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  .confirmation-top-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 60px;

    .confirmation-tab-container {
      display: flex;
      height: 50px;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;

      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;

      .tab-item {
        display: flex;
        height: 30px;
        width: 60px;
        min-width: 60px;
        border-radius: 15px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 15px;
      }
    }

    .confirmation-date-container {
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;

      .today {
        color: black;
        font-size: 17px;
      }
      .date {
        margin-left: 5px;
        font-size: 12px;
        color: #999999;
      }
    }

    .confirmation-data-container {
      min-height: 75vh;
      overflow: scroll;

      .travel-item-container {
        display: flex;
        flex-direction: column;

        .travel-item-line-container {
          height: 24px;
          width: 74px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .travel-item-line {
            height: 24px;
            width: 1px;
            background-color: #dfdfdf;
          }
        }

        .travel-item {
          height: 44px;
          display: flex;
          flex-direction: row;

          .travel-item-image {
            width: 44px;
            margin-left: 15px;
            margin-right: 15px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .travel-item-message {
            height: 44px;
            max-width: 70vw;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .travel-item-message-name {
              color: #333333;
              font-size: 14px;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .travel-item-message-label {
              color: #5c5c5c;
              font-size: 12px;
              margin-top: 10px;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .confirmation-buy-btn {
    height: 50px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    line-height: 50px;
    text-align: center;
    background-color: #547efe;
    color: white;
    font-size: 17px;
  }

  .line {
    display: block;
    height: 1px;
    background-color: #e1e1e1;
    width: 100vw;
  }

}
