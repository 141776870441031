html, body {
  .amap-sug-result {
    display: none !important;
  }
  .amap-sug-result .auto-item {
    height:30px !important;
    line-height: 30px !important;
    font-size: 12px !important;
    padding-left: 20px !important;
  }
}
.guide-wrapper {
  height: 100%;
  width: 100%;
  background-color: #F9F9F9;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .header-wrapper {
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    .currentNum {
      height: 0px;
      width: 100%;
      font-size: 15px;
      color: #323232;
      position: relative;
      .input-wrapper {
        display: flex;
        position: absolute;
        top: 4px;
        left: 14px;
        right: 14px;
        padding: 8px 6px 8px 16px;
        background-color: #fff;
        border-radius: 2px;
        input {
          flex: 1;
          width: 100%;
          border: none;
        }
        .go-to {
          height: 36px;
          width: 60px;
          background:rgba(197,197,197,1);
          border-radius:4px;
          color: #fff;
          border: none;
          &.active {
            background-color: #2A73FE;
          }
        }
      }
      #complete {
        // min-height: 0px;
        position: absolute;
        top: 60px;
        left: 14px;
        right: 14px;
        z-index: 1000;
        min-height:0px;
        background-color: #ffffff;
        height: 80vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        ::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
        }
        .addlist {
          border-bottom: 1px solid #eee;
          text-align: left;
          padding: 5px 10px;
          .add {
            height:30px;
            line-height:30px;
            font-size: 14px;
            color: #4e4e4e;
            padding-top: 3px;
          }
          .depadd {
            font-size: 12px;
            color: #c0c0c0;
            padding-bottom: 3px;
          }
        }
      }
      &.divRed {
        background-color: #FFF6F6;
      }
      &.divGreen {
        background-color: #F7FFF1;
      }
      &.divYellow {
        background-color: #FFFBF6;
      }
      span {
        &.spanRed {
          color: #D0021B;
        }
        &.spanGreen {
          color: #417505;
        }
        &.spanYellow {
          color: #F5A623;
        }
      }
    }
  }
  .content-wrapper {
    position: fixed;
    bottom: 14px;
    left: 14px;
    right: 14px;
    border-radius: 2px;
    z-index: 99;
    .other-tab {
      position: relative;
      width: 15%;
      background-color: #fff;
      line-height: 40px;
      padding: 0 10px 0 14px;
      margin-bottom: 10px;
      height: 40px;
      border-radius: 0px 40px 40px 0;
      color: #666666;
      transition:width 0.5s;
      -moz-transition: width 0.5s;
      -webkit-transition: width 0.5s;
      -o-transition: width 0.5s;
      overflow: hidden;
      .other-list {
        display: flex;
        li {
          flex: 1;
          span {
            display: inline-block;
            color: #333;
            font-size: 13px;
            &:nth-child(1) {
              margin-right: 10px;
              img {
                position: relative;
                top: 4px;
                height: 18px;
                width: 18px;
              }
            }
          }
        }
      }
      .icon {
        padding: 10px;
        position: absolute;
        top: 0px;
        right: 1px;
      }
    }
    .car-list {
      background-color: #fff;
      padding: 0 6px 0 10px;
      border-radius: 4px;
      .placeholder-div {
        height: 230px;
        line-height: 230px;
        text-align: center;
      }
      ul{
        .list-item {
          &:last-child{
            .item-text {
              border-bottom: none;
            }
            .item-rigth-icon {
              border-bottom: none;
            }
          }
          display: flex;
          padding: 16px 11px 0 0;
          .item-icon {
            margin-right: 8px;
            img {
              height: 24px;
              width: 24px;
              position: relative;
              top: -3px;
            }
          }
          .item-text {
            position: relative;
            flex: 1;
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 16px;
            overflow: hidden;
            .recommend {
              position: absolute;
              right: 2px;
              top: 0px;
              color: #33AC1C;
              border: 1px solid #33AC1C;
              padding: 2px 3px;
              border-radius: 3px;
            }
            h3 {
              font-size: 16px;
              color: #202020;
              font-weight: 500;
              span{
                margin-left: 15px;
                font-size: 12px;
                font-weight: normal;
                &.spanRed {
                  color: #D0021B;
                }
                &.spanYellow {
                  color: #F5A623;
                }
                &.spanGreen {
                  color: #417505;
                }
              }
            }
            p{
              margin-top: 12px;
              font-size: 13px;
              color: #999999;
              white-space:nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .item-rigth-icon {
            border-bottom: 1px solid #E5E5E5;
            .icon {
              position: relative;
              top: 10px;
            }
          }
        }
      }
    }
  }
}
