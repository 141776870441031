.details-container {
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
	min-height: 100vh;
}

.details-top-img {
	height: 370px;
	width: 100%;
	border: 0;
}
.am-drawer.am-drawer-bottom .am-drawer-draghandle {
	height: 0px;
}

.close-img {
	width: 20px;
	height: 20px;
	position: absolute;
	right: 15px;
	top: 10px;
}

.drawer-container {
	justify-content: flex-end;
	border-radius: 5px 5px 0px 0px;
	background-color: #ffffff;
	margin-bottom: 50px;
	height: 54vh;
	overflow-y: auto;

	.txt-use-pop {
		margin-top: 20px;
		font-size: 14px;
		color: #333333;
		display: block;
	}

	.add-padding {
		padding: 25px 15px 0px 15px;
	}

	.top-view {
		flex-direction: row;
		display: flex;
		margin-top: 20px;
		align-items: center;

		.left {
			flex-direction: row;
			display: flex;
			margin-right: 10px;

			.left-img {
				display: block;
				width: 65px;
				height: 65px;
				border-radius: 4px;
			}

			.left-txt-box {
				flex-direction: column;
				display: flex;
				justify-content: center;
				padding-left: 10px;

				.left-txt-name {
					height: 30px;
					line-height: 30px;
					color: #333333;
					font-size: 15px;
				}
			}
		}

		.right {
			width: 100%;
		}
	}
	.price-box {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		align-items: center;

		.left-txt-price {
			color: #e03131;
			font-size: 15px;
		}

		.right-txt {
			color: #999999;
			font-size: 12px;
		}
	}

	.ticket-box {
		display: flex;
		margin-top: 8px;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		.ticket-item-checked {
			border: 1px solid #547eff;
			border-radius: 20px;
			color: #547eff;
			font-size: 14px;
			padding: 4px 10px;
			margin-top: 12px;
			background-color: #547eff33;
			margin-right: 12px;
		}
		.ticket-item-unchecked {
			border: 1px solid #bfbfbf;
			border-radius: 20px;
			color: #bfbfbf;
			margin-top: 12px;
			font-size: 14px;
			margin-right: 12px;
			padding: 4px 10px;
			background-color: #ffffff;
		}

		.perch {
			width: 20px;
			height: 30px;
			display: block;
		}
	}

	.sel-num-box {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
		height: 40px;
		align-items: center;
		margin-bottom: 20px;
		margin-top: 10px;

		.txt-select-num {
			font-size: 14px;
			color: #333333;
		}

		.img-shop-operation {
			width: 24px;
			height: 20px;
			padding: 5px;
		}

		.txt-select {
			font-size: 14px;
			color: #333333;
			display: block;
		}

		.right {
			display: flex;
			align-items: center;

			.num {
				color: #333333;
				display: flex;
				width: 30px;
				height: 20px;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.details-top-info {
	background-color: #ffffff;
	padding: 10px 15px;

	.ticket-name {
		font-size: 15px;
		color: #333333;
		font-weight: bold;
	}
	.ticket-price {
		font-size: 15px;
		color: #333333;
		height: 35px;
		line-height: 35px;
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.ticket-price-txt {
			color: #e03131;
			font-size: 15px;
			font-weight: bold;
		}

		.ticket-sell-txt {
			color: #999999;
			font-size: 12px;
		}
	}
}

.select-use-people {
	height: 45px;
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	padding: 0px 15px;
	align-items: center;
	margin-top: 12px;
	font-size: 16px;
	color: #333333;
}

.inner-html {
	margin-top: 15px;
	background-color: #ffffff;
	padding: 12px 12px;
	margin-bottom: 75px;
}

.bottom-view {
	display: flex;
	align-items: center;
	justify-content: center;
}

.bottom-view-parent {
	z-index: 10;
	display: flex;
	width: 100%;
	position: fixed;
	bottom: 0;
}
.confirm-cancel {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	height: 50px;
	background-color: #dee6ff;
	font-size: 17px;
	color: #547eff;
	font-weight: bold;
	flex: 1;
}
.confirm-btn {
	display: flex;
	flex: 1;
	width: 100%;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	height: 50px;
	background-color: #547eff;
	font-size: 17px;
	color: #ffffff;
}
