* {
  padding: 0;
  margin: 0;
}
.list-file {
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
  .header {
    width: 100%;
    height: 44px;
    background-color: #fff;
    color: #000000;
    font-size: 18px;
    .am-navbar-left {
      padding-left: 0;
    }
    .am-navbar-right {
      margin-right: 0;
    }
    .am-navbar-title {
      color: #000000;
      font-weight: 500;
    }
  }
  .content {
    width: 100%;
    margin-bottom: 30px;
    .box {
      margin: 0 15px;
      background-color: #fff;
      display: flex;
      margin-top: 10px;
      font-size: 15px;
      border-radius: 2px;
      position: relative;
      .img-wrapper {
        width: 84px;
        height: 70px;
        margin: 15px 10px 15px 0;
      }
      .img {
        height: 100%;
        width: 100%;
      }
      .right {
        flex: 1;
        padding: 15px 0 15px;
        .p {
          flex:1;
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
        }
        .margin {
          margin: 14px 0 8px;
        }
        .bottom {
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
          color: #666666;
        }
      }
      .flx {
        position: relative;
        width: 45px;
        .position {
          width: 30px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 5px;
          margin: auto;
        }
      }
    }
  }
}
