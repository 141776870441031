body, html {
  height: 100%;
  width: 100%;
}
.addItinerary {
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  .content-wrapper {
    width: 100%;
    min-height: 100%;

    .content {
      padding-bottom: 20px;

      .center {
        text-align: center;

        .banner {
          width: 100%;
          // height: 198px;

          img {
            display:block; // 图片等比缩放
            // height: 100%;
            width: 100%;
          }
        }

        .tip {
          font-size: 18px;
          color: #323232;
          font-weight: bold;
        }

        .btn {
          height: 45px;
          width: 140px;
          margin-top: 20px;
          line-height: 45px;
          background-color: #2A73FE;
        }
      }

      .container {
        margin: 30px 15px 20px 15px;
        display: flex;
        flex-direction: column;

        .navList {
          display: flex;

          .nav-item {
            padding: 19px 0;
            height: 37px;
            flex: 1;
            display: flex;
            background-color: #F5F8FF;
            border-radius: 2px;
            &:nth-child(1) {
              .img {
                margin: 5px 10px 5px 10px;

                img {
                  height: 27px;
                  width: 34px;
                }
              }
            }
            &:nth-child(2) {
              .img {
                margin: 3px 10px 5px 10px;

                img {
                  height: 35px;
                  width: 29px;
                }
              }
            }

            .text {
              flex: 1;
              span {
                display: block;
                &:nth-child(1) {
                  font-weight: bold;
                  font-size: 15px;
                  color: #323232;
                }
                &:nth-child(2) {
                  margin-top: 5px;
                  font-size: 12px;
                  color: #999;
                }
              }
            }

            &:nth-child(2) {
              margin-left: 10px;
            }
          }
        }
        .commonTitle {
          font-size: 18px;
          font-weight: bold;
          margin: 36px 0 18px 0;
          color: #323232;
        }
        .commonList {
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .common-item {
            width: 33.33333333333%;
            margin-top: 20px;
            &:nth-child(1),&:nth-child(2),&:nth-child(3) {
              margin-top: 0px;
            }
            .common-wrapper {
              text-align: center;

              img {
                height: 36px;
                width: 36px;
              }

              span {
                display: block;
                margin-top: 10px;
                font-size: 13px;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: relative;
    padding-bottom: 10px;
    margin: -20px 20% 0 20%;
    height: 20px;
    clear: both;
    text-align: center;
    line-height: 20px;
    display: flex;
    .line1 {
      flex: 1;
      height: 1px;
      border-top: 1px solid #DDDDDD;
      position: relative;
      bottom: -10px;
    }
    .text {
      margin: 0 10px;
      font-size: 13px;
      color: #CCCCCC;
    }
  }
}
