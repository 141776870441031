.text-wrapper {
  margin: 0 14px;
  height: 90vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-top: 45px;
  .text-title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    color: #333333;
    font-weight: bold
  }

  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .title-type {
    padding: 20px 0 15px 0;
  }
  .type-list {
    width: 100%;
    .type-item {
      display: flex;
      padding: 18px 5px;
      margin-bottom: 10px;
      background-color: #fff;
      .left, .right {
        img {
          height: 24px;
          width: 24px;
        }
        &.left {
          position: relative;
          top: -2px;
        }
        &.right {
          width: 48px;
          background-image: url('../../img/mark.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 24px 24px;
        }
      }
      .text {
        flex: 1;
        padding-top: 2px;
        margin: 0 4px;
        p {
          color: #333333;
          font-size: 15px;
          margin-bottom: 14px;
          font-weight: Bold;
        }
        span {
          color: #666666;
          font-size: 13px;
          &.spanRed {
            color: #D0021B;
          }
          &.spanYellow {
            color: #F5A623;
          }
          &.spanGreen {
            color: #417505;
          }
        }
      }
    }
  }
}