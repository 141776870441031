body {
	padding: 0;
	margin: 0;
}

.home-container {
	display: flex;
	flex-direction: column;

	.tab-bar {
		display: flex;
		flex-direction: row;
		background-color: #ffffff;

		.tab-bar-btn-press {
			height: 50px;
			display: flex;
			flex: 1;
			align-items: center;

			flex-direction: column;
			justify-content: center;
			font-size: 17px;
			color: #547eff;

			.line {
				width: 50%;
				background-color: #547eff;
				height: 2px;
				border-radius: 3px;
				position: absolute;
				top: 45px;
			}
		}

		.tab-bar-btn-un-press {
			height: 50px;
			display: flex;
			flex: 1;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			font-size: 17px;
			color: #a5a5a5;
		}
	}

	.tab-container {
		display: flex;
		flex-direction: column;
		padding: 0px 15px;
		background-color: #fafafa;
		.home-image {
			object-fit: cover;
			width: 100%;
			margin-top: 20px;
		}
	}

	.home-container-btn {
		height: 150px;
		background-color: #f5f7ff;
		display: flex;
		border: 1px dashed #c6d1ff;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 20px;

		.home-container-btn-add-icon {
			width: 35px;
		}

		.home-container-btn-txt {
			font-size: 15px;
			clear: #547eff;
			margin-top: 12px;
		}
	}

	.main-right {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.img {
			height: 180px;
			width: 180px;
			margin-top: 80px;
		}
		.font_1 {
			color: #999999;
			margin-top: 50px;
			margin-bottom: 20px;
			font-size: 14px;
		}
		.font_2 {
			color: #547eff;
			font-size: 22px;
		}
	}
}

.flex-container {
	min-height: 100vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding-bottom: 40px;
}

.btn-next {
	margin-bottom: 20px;
}

.line {
	display: block;
	height: 1px;
	background-color: #e1e1e1;
	width: 100%;
}

.app-header {
	border-bottom: 1px solid #e1e1e1;
	height: 50px;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #4a4a4a;
	flex-direction: row;
	a {
		color: #4a4a4a;
		position: absolute;
		left: 30px;
		text-decoration: none;
	}
}

.container {
	display: flex;

	.container-left {
		flex: 0.4;
		border-right: 1px solid #e1e1e1;
		min-height: 100vh;
		.container-left-list {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 50px;
			border-bottom: 1px solid #e1e1e1;
		}

		.container-left-btn {
			margin: 10px 0px;
			border: 0;
			border-radius: 0px;
			border-bottom: 1px solid #e1e1e1;
		}
	}
	.container-right {
		flex: 0.6;
		min-height: 100vh;
		padding: 10px;

		.container-right-top {
			display: flex;

			.container-right-top-btn {
				padding: 0px 30px;
				display: flex;
				height: 44px;
				margin: 0px 10px;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
