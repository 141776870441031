
.privacy {
    width: 100%;
    height: 100%;
    background-color: #F4F4F4;
    .title {
        padding-top: 20px;
        font-size: 20px;
        text-align: center;
    }
    .content {
        margin: 20px;
        p {
            color: #666666;
            font-size: 15px;
            line-height: 30px;
        }
        .headline{
            font-size: 17px;
            color:#000000;
        }
    }
}
