* {
  padding: 0;
  margin: 0;
}
.info-input {
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    height: 44px;
    background-color: #fff;
    color: #000000;
    font-size: 18px;
    .am-navbar-left {
      padding-left: 0;
    }
    .am-navbar-right {
      margin-right: 0;
    }
    .am-navbar-title {
      color: #000000;
      font-weight: 500;
    }
  }
  .content {
    width: 100%;
    height: 90vh;
    position: fixed;
    top: 44px;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-direction: row;
    flex-direction: row;
    .infoList {
      margin-bottom: 30px;
      .list {
        margin-top: 20px;
        padding: 0 14px;
        background-color: #F9F9F9;
        p {
          font-size: 16px;
          font-weight: 800;
          color: #333333;
          margin-bottom: 12px;
        }
        .number {
          height: 50px;
          line-height: 50px;
          font-size: 15px;
          color: #333333;
          background-color: #fff;
          .update {
            height: 25px;
            width: 40px;
            border-radius: 2px;
            text-align: center;
            font-size: 12px;
            font-weight:500;
            color: #fff;
            background-color: #2A73FE;
            line-height: 25px;
            display: inline-block;
          }
        }
      }
      .flowrate {
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        color: #333333;
        margin: 15px;
        background-color: #fff;
        .flowrateUpdate {
          height: 25px;
          width: 40px;
          border-radius: 2px;
          text-align: center;
          font-size: 12px;
          font-weight:500;
          color: #fff;
          background-color: #2A73FE;
          line-height: 25px;
          display: inline-block;
        }
      }
    }
  }
}
.am-modal-button-group-h .am-modal-button {
  font-size: 16px!important;
}
