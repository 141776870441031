.nearby-car {
  height: 100%;
  width: 100%;
  background-color: #F9F9F9;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .content {
    margin: 0 14px;
    .content-title {
      padding: 20px 0 15px 0;
      font-size: 16px;
    }
    .content-list {
      width: 100%;
      .content-item {
        display: flex;
        padding: 18px 14px;
        margin-bottom: 10px;
        background-color: #fff;

        .text {
          flex: 1;
          p {
            color: #333333;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 14px;
          }
          span {
            i {
              font-style: normal;
              color: #666666;
              font-size: 13px;
              &:nth-child(2) {
                margin-left: 15px;
              }
            }
          }
        }
        .right {
          width: 30px;
          text-align: center;
          line-height: 47px;
          font-size: 24px;
          color: #999;
        }
      }
    }
  }
}