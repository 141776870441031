.selcet-train-ticket-container {
	min-height: 100vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background: #fafafa;

	.list-view {
		width: 100vw;
		// height: 100vh;
		overflow: auto;
		.am-list-body {
			background: #fafafa;
		}
		// .am-pull-to-refresh-content-wrapper{
		// 	height: 100vh;
		// }
	}
	.app-header {
		border-bottom: 1px solid #e1e1e1;
		height: 50px;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #4a4a4a;
		flex-direction: row;
		a {
			color: #4a4a4a;
			position: absolute;
			left: 30px;
			text-decoration: none;
		}
	}

	.affirm-button {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		background-color: #547eff;
		font-size: 17px;
		color: #ffffff;
		margin-top: 30px;
		border-radius: 4px;
	}
}

.item {
	display: flex;
	background: #ffffff;
	padding-top: 15px;
	padding-bottom: 15px;
	margin: 10px;
	border-radius: 4px;

	.item-icon {
		margin-left: 15px;
		width: 50px;
		height: 50px;
	}

	.item-content {
		display: flex;
		margin-left: 10px;
		flex-direction: column;
		justify-content: space-around;

		.item-content-title {
			font-size: 16px;
			font-family: PingFang-SC-Bold;
			font-weight: bold;
			color: #333333;
		}

		.item-content-symbol {
			font-size: 12px;
			color: #e03131;
		}

		.item-content-price {
			font-size: 16px;
			color: #e03131;
			margin-left: 5px;
			font-family: PingFang-SC-Bold;
			font-weight: bold;
		}
	}
}
